import constants from "./constants";

class ApiBlog {
  constructor() {
    this.serviceHost = "";
    this.apiHost = constants.revJsonStorageHost;
    this.client = constants.revClientId;
  }

  async getService() {
    const resp = await fetch(`${this.serviceHost}?client_id=${this.client}`);

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    let services = await resp.json();

    let service = services.find(sv => sv.service_name === "jstorage");
    service = {
      ...service,
      service_name: "articles",
      display_name: "Revtel-Article-Service",
    };

    this.apiHost = `${service.domain}/${service.latest}`;
  }

  async getBlogLabels() {
    let url = `${this.apiHost}/document/categories/find-one?client_id=${this.client}`;

    let data = {
      query: {
        name: "articles",
      },
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  }

  async getFaqLabels() {
    let url = `${this.apiHost}/document/categories/find-one?client_id=${this.client}`;

    let data = {
      query: {
        name: "faqs",
      },
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  }

  async getBlogs({ documentName, paging, ...params }) {
    let url = `${this.apiHost}/document/${documentName}/find?client_id=${this.client}`;

    let data = {
      query: params,
      sorting: ["-updated"],
      paging,
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  }

  async getBlog({ documentName, ...params }) {
    let url = `${this.apiHost}/document/${documentName}/find-one?client_id=${this.client}`;

    let data = {
      query: params,
    };

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (resp.status.toString()[0] !== "2") {
      throw new Error(resp);
    }
    return await resp.json();
  }
}

export default ApiBlog;
